import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "timi-project-container" }
const _hoisted_2 = { class: "timi-frontend-content" }
const _hoisted_3 = { class: "form-layout" }
const _hoisted_4 = {
  key: 0,
  class: "timi-form-item col-4"
}
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = {
  key: 1,
  class: "timi-form-item col-4"
}
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "timi-form-item col-4" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 2,
  class: "timi-form-item"
}
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconButton, {
        icon: _ctx.store.getters.svgIcons.arrowbackward,
        class: "go-back-btn",
        label: _ctx.$t('button.back'),
        dense: true,
        onOnClick: _ctx.handleGoBack
      }, null, 8, ["icon", "label", "onOnClick"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
        }, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("project.projectData")), 1),
          (_ctx.TODO)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.projectNumberExternal')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: _ctx.$t('placeholder.projectNumberExternal'),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.numberExternal) = $event))
                }, null, 8, _hoisted_5), [
                  [_vModelText, _ctx.form.numberExternal]
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.TODO)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.projectNumberInternal')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: _ctx.$t('placeholder.projectNumberInternal'),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.numberInternal) = $event))
                }, null, 8, _hoisted_7), [
                  [_vModelText, _ctx.form.numberInternal]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.projectNumberSerial')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: _ctx.$t('placeholder.projectNumberSerial'),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.numberSerial) = $event))
            }, null, 8, _hoisted_9), [
              [_vModelText, _ctx.form.numberSerial]
            ])
          ]),
          (_ctx.TODO)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.title')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: _ctx.$t('placeholder.title'),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.title) = $event))
                }, null, 8, _hoisted_11), [
                  [_vModelText, _ctx.form.title]
                ])
              ]))
            : _createCommentVNode("", true)
        ], 32)
      ]),
      _createElementVNode("div", _hoisted_12, [
        (!_ctx.editMode)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              text: _ctx.$t('button.addProject'),
              primary: true,
              raised: true,
              onOnClick: _ctx.handleCreate
            }, null, 8, ["text", "onOnClick"]))
          : (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              text: _ctx.$t('button.save'),
              primary: true,
              raised: true,
              onOnClick: _ctx.handleSave
            }, null, 8, ["text", "onOnClick"]))
      ])
    ])
  ]))
}